import * as React from "react";
import * as styles from "./index.module.css";

import { FunctionComponent, useCallback, useState } from "react";

import {
  Button,
} from "@chakra-ui/react";
import ContactForm from "../components/Contact/ContactForm";
import Layout from '../components/Base/Layout';
import { SEO } from "../components/Base/Seo";
import { navigate } from "gatsby";
import useAuth from "../components/useAuth";

const Home: FunctionComponent = () => {
  const { loggedIn, isMerchantAcc } = useAuth();

  const onButton4Click = useCallback(() => {
    navigate("/about-us");
  }, []);


  const onButton6Click = useCallback(() => {
    navigate("/shop/category/medical-devices");
  }, []);

  const onButto7Click = useCallback(() => {
    navigate("/shop/category/medical-conveyance-aid");
  }, []);

  const onButto8Click = useCallback(() => {
    navigate("/shop/category/disposable-practice-supply");
  }, []);

  const onButto9Click = useCallback(() => {
    navigate("/shop/category/ppe");
  }, []);

  const onButto10Click = useCallback(() => {
    navigate("/shop/category/practice-instruments");
  }, []);

  const onButto11Click = useCallback(() => {
    navigate("/shop/category/practice-supply");
  }, []);

  return (
    <Layout>
      <div className={styles.home}>

        <div className={styles.heroSection}>
          <div className={styles.heroContent}>
            <div className={styles.logo2}>
              <img
                className={styles.cdihealthlogoIcon2}
                alt=""
                src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/cdi-health-logo.png"
              />
            </div>
            <div className={styles.heroText}>
              <div className={styles.title}>
                <div className={styles.display}>
                  Affordable solutions for all of your medical product needs
                </div>
              </div>
              <div className={styles.p}>
                <div className={styles.caption}>
                  At CDI Health, we provide a large selection of health and
                  wellness products�including Personal Protective Equipment
                  (PPE), canes, walkers, cushions, and more� we can help you
                  provide your patients with the equipment they need to live
                  healthier, more comfortable lives.
                </div>
              </div>
            </div>
            <div className={styles.heroActions}>
              <Button
                className={styles.button}
                variant="outline"
                colorScheme="cdicyan"
                size="lg"
                onClick={onButton4Click}
              >
                About Us
              </Button>
              <Button variant="solid" colorScheme="cdicyan" size="lg" onClick={onButton6Click}>
                Start Shopping
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.cardsSection}>
          <div className={styles.sectionTitle}>
            <h2 className={styles.display1}>What We Offer</h2>
          </div>
          <div className={styles.cardsRow}>
            <div className={styles.cardSlot}>
              <div className={styles.card}>
                <div className={styles.cardContent}>
                  <div className={styles.title}>
                    <b className={styles.label}>Wholesale Medical Equipment</b>
                  </div>
                  <div className={styles.p1}>
                    <div className={styles.caption1}>
                      CDI Health offers medical clinics, medical spas,
                      pharmaceutical stores, private practices, and medical
                      supply wholesalers the most competitive pricing for the
                      equipment they need.
                    </div>
                  </div>
                  <Button
                    className={styles.button}
                    variant="solid"
                    colorScheme="cdicyan"
                    onClick={onButton6Click}
                  >
                    Learn More
                  </Button>
                </div>
                <div className={styles.cardImg}>
                  <img
                    className={styles.depositphotos349498906XL1Icon}
                    alt=""
                    src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/depositphotos-349498906-xl-1@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.categoriesSection}>
          <div className={styles.sectionTitle}>
            <div className={styles.display2}>Product Categories</div>
          </div>
          <div className={styles.categoryCards}>
            <div className={styles.catCardRow}>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon}
                    alt=""
                    src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/image.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label1}>Disposable Practice Supply</div>
                    <Button
                      className={styles.button4}
                      variant="solid"
                      colorScheme="cdicyan"
                      onClick={onButto8Click}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon1}
                    alt=""
                    src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/image2.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label1}>Medical Conveyance Aid</div>
                    <Button
                      className={styles.button4}
                      variant="solid"
                      colorScheme="cdicyan"
                      onClick={onButto7Click}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon1}
                    alt=""
                    src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/image3.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label1}>Practice Instruments</div>
                    <Button
                      className={styles.button4}
                      variant="solid"
                      colorScheme="cdicyan"
                      onClick={onButton6Click}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.catCardRow}>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon1}
                    alt=""
                    src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/image4.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label1}>
                      Practice Supply
                    </div>
                    <Button
                      className={styles.button4}
                      variant="solid"
                      colorScheme="cdicyan"
                      onClick={onButto10Click}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon1}
                    alt=""
                    src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/image5.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label1}>
                      PPE
                    </div>
                    <Button
                      className={styles.button4}
                      variant="solid"
                      colorScheme="cdicyan"
                      onClick={onButto9Click}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon1}
                    alt=""
                    src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/image6.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label1}>Medical Devices</div>
                    <Button
                      className={styles.button4}
                      variant="solid"
                      colorScheme="cdicyan"
                      onClick={onButton6Click}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.whySection}>
          <div className={styles.whyImg}>
            <img
              className={styles.depositphotos349498186S1Icon}
              alt=""
              src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/depositphotos-349498186-s-1@2x.png"
            />
          </div>
          <div className={styles.features}>
            <div className={styles.sectionTitle2}>
              <h2 className={styles.display3}>Why Us</h2>
            </div>
            <div className={styles.featuresRow}>
              <div className={styles.featureSlot}>
                <div className={styles.feature}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/medicine-syrup.png"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Large Product Range</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      We use an extensive network of suppliers to ensure we have
                      a large selection of medical equipment and products for
                      our clients to choose from.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlot}>
                <div className={styles.feature}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/premium-rights.png"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Low Prices</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      Our highly affordable pricing sets our business apart. Due
                      to our network of international partners, we are able to
                      supply medical products that fit your budget and that of
                      your patients or customers.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlot2}>
                <div className={styles.feature2}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/ship.png"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Fast Delivery</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      Our customers rely on us to deliver the products they need
                      for their medical clinics and pharmaceutical supply stores
                      in a timely manner. So we ensure all products are
                      delivered on time and without hassle.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.featuresRow}>
              <div className={styles.featureSlot}>
                <div className={styles.feature}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/shield-check.png"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Trusted Brands</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      We only stock authentic, high-quality products, including
                      the brand names your patients know and love.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlot}>
                <div className={styles.feature}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/headset.png"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Great Customer Service</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      We know that professional and reliable service lies at the
                      heart of any successful business. Our team works hard so
                      that all our customers are satisfied with their products
                      and the service they receive.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlot}>
                <div className={styles.feature2}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/world.png"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Global Reach</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      We source our products from suppliers across the globe.
                      With such a wide network, we are able to ship to any
                      country the customer may be located in.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.contactSection}
          data-scroll-to="contactSectionContainer"
        >
          <div className={styles.contactContent} id="contactSectionContainer">
            <ContactForm></ContactForm>
          </div>
          <div className={styles.contactDetailsSignIn}>
            <div className={styles.contactDetails}>
              <div className={styles.logo3}>
                <img
                  className={styles.cdihealthlogoIcon}
                  alt=""
                  src="../cdihealthlogo4.svg"
                />
              </div>
              <div className={styles.title1}>
                <div className={styles.display5}>Need Anything?</div>
              </div>
              <div className={styles.visibleDetails}>
                <div className={styles.details}>
                  <div className={styles.label19}>Call us:</div>
                  <b className={styles.label7}>+1-855-629-2037</b>
                </div>

                {!loggedIn ? (
                  <></>
                ) : (
                  <>
                    <div className={styles.details}>
                      <div className={styles.label19}>Address:</div>
                      <b className={styles.label7}>
                        {isMerchantAcc ? '5800 Ambler Drive, Suite 210, Mississauga, Ontario L4W 4J4.' : '152/NO 9, NAXXAR ROAD SAN GWANN, SGN 9030, MALTA'}
                      </b>
                    </div>
                  </>
                )}

                <div className={styles.details}>
                  <div className={styles.label19}>Monday to Friday</div>
                  <b className={styles.label7}>9AM - 5PM EST</b>
                </div>
              </div>
              <div className={styles.signInBtn}>
                <Button
                  className={styles.button4}
                  variant="solid"
                  colorScheme="cdicyan"
                >
                  <a href="/log-in"> Sign In To Order </a>
                </Button>
              </div>
              <div className={styles.label25}>
                {loggedIn ? <></> : <div className={styles.label26}>
                  Please sign in for address
                </div>}

              </div>
              <div className={styles.ccs}>
                <img className={styles.visaIcon} alt="" src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/Visa.png" />
                <img
                  className={styles.visaIcon}
                  alt=""
                  src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/Mastercard.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;

export const Head = () => (
  <SEO title="CDI Health" description="Page description" />
)
